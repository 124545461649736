import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";

function App() {
  const [numberApples, setNumberApples] = useState(0);
  const [numberOranges, setNumberOranges] = useState(0);

  // useEffect(() => {
  //   window.addEventListener("message", (event) => {
  //     console.log("Overall response", event.data);
  //
  //     if (event.data.msg === "sportal365_custom_event_onload") {
  //       console.log("CMS Responded with", event.data.payload);
  //     }
  //
  //     if (event.data.msg === "sportal365_custom_event_callback") {
  //       console.log("Data successfully saved");
  //     }
  //   });
  // }, []);

  /* CALLBACK LOGIC FOR RECEIVING MESSAGES FROM CMS
    * 1. Listen for message events
    * 2. Callback function
    * 4. Receive message from CMS: event.data.msg: sportal365_custom_event_onload and sportal365_custom_event_callback
    * 5. Receive data saved in the CMS: event.data.payload
    * 6. Set state with data received from CMS
     */
  const receiveMessageFromIframePage = (event) => {
    console.log('receiveMessageFromCMS 1', event);
    if(event.data.msg === 'sportal365_custom_event_onload' || event.data.msg === 'sportal365_custom_event_callback') {
      console.log('receiveMessageFromCMS 2', event);
      // const parsedMessage = JSON.parse(event.data.payload);
      // if (parsedMessage.name) {
      //   console.log('Set name');
      // }
      // if (parsedMessage.oranges) {
      //   console.log('Set selectedOption');
      // }
    }
    console.log('receiveMessageFromCMS 3', event);
  };

  useEffect(() => {
    window.addEventListener("message", receiveMessageFromIframePage, false);
    return () => {
      window.removeEventListener("message", receiveMessageFromIframePage);
    };
  });

  const submitChanges = () => {
    const post = {"oranges":numberOranges, "apples":numberApples};
    console.log('Send data to parent');
    window.parent.postMessage({"msg": "sportal365_custom_event", "payload": JSON.stringify(post)}, '*');
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div style={{marginBottom: "25px"}}>
          <label>Apples</label>
          <div>
            <input type='number' value={numberApples} placeholder='Input number of apples'
                   style={{height: "25px", width: "150px", paddingLeft: "5px", paddingRight: "5px"}}
                   onChange={(evt) => setNumberApples(+evt.target.value)}/>
          </div>
        </div>
        <div style={{marginBottom: "10px"}}>
          <label>Oranges</label>
          <div>
            <input placeholder='Input number of oranges' type='number' value={numberOranges}
                   style={{height: "25px", width: "150px", paddingLeft: "5px", paddingRight: "5px"}}
            onChange={(evt) => setNumberOranges(+evt.target.value)}/>
          </div>
        </div>
        <div>
          <button style={{width: "100px", height: "25px", borderRadius: "6px", border: "none", cursor: 'pointer'}}
          onClick={ () => submitChanges}>Submit</button>
        </div>
      </header>
    </div>
  );
}

export default App;
